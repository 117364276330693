import * as types from './memberPaymentsTypes';
import * as loadingActions from '../../Loading/loadingActions';
import * as partnersListService from '../../Partners/PartnersList/partnersListService';
import * as memberPaymentsService from './memberPaymentsService';
import * as paymentTypeService from '../../../features/PaymentTypes/paymentTypeService';

const setPartnersAction = (partners) => {
    return { type: types.SET_PARTNERS, partners };
};

export function getPartnersListByProgramPaymentType(programPaymentTypeID) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_PARTNERS));
        return partnersListService
            .getPartnersListByProgramPaymentType(programPaymentTypeID, null)
            .then((partners) => {
                dispatch(setPartnersAction(partners));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_PARTNERS));
            });
    };
}

const setPeriodIDAction = (periodID) => {
    return { type: types.SET_PERIOD_ID, periodID };
};

export function setPeriodID(periodID) {
    return function (dispatch) {
        dispatch(setPeriodIDAction(periodID));
    };
}

const setProgramTypeIDAction = (programTypeID) => {
    return { type: types.SET_PROGRAM_PAYMENT_TYPE_ID, programTypeID };
};

export function setProgramTypeID(programTypeID) {
    return function (dispatch) {
        dispatch(setProgramTypeIDAction(programTypeID));
    };
}

const setPaymentsAction = (payments) => {
    return { type: types.SET_PAYMENTS, payments };
};

export function getPayments(programPaymentTypeID, paymentTypeID, partnerId, periodId) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_PAYMENTS));
        return memberPaymentsService
            .getPayments(programPaymentTypeID, paymentTypeID, partnerId, periodId)
            .then((payments) => {
                dispatch(setPaymentsAction(payments));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_PAYMENTS));
            });
    };
}

export function downloadPayments(programPaymentTypeID, paymentTypeID, partnerID, periodID) {
    return function(dispatch, getState) {
        dispatch(loadingActions.setLoading(types.DOWNLOAD_PAYMENTS));
        return memberPaymentsService
            .downloadPayments(programPaymentTypeID, paymentTypeID, partnerID, periodID)
            .finally(() => dispatch(loadingActions.unsetLoading(types.DOWNLOAD_PAYMENTS)));
    };
}

const setPopupDataAction = (popupData) => {
    return { type: types.SET_POPUP_DATA, popupData };
};

export function checkMemberPaymentsProcessed(programPaymentTypeID, paymentTypeID, partnerID, periodID) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.CHECK_PAYMENTS_PROCESSED));
        return memberPaymentsService
            .checkMemberPaymentsProcessed(programPaymentTypeID, paymentTypeID, partnerID, periodID)
            .then((paymentsProcessedData) => { dispatch(setPopupDataAction(paymentsProcessedData)); })
            .finally(() => dispatch(loadingActions.unsetLoading(types.CHECK_PAYMENTS_PROCESSED)));
    };
}

function sendMemberPayments(programPaymentTypeID, paymentTypeID, partnerID, periodID, pin) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.SEND_PAYMENTS));
        return memberPaymentsService
            .sendMemberPayments(programPaymentTypeID, paymentTypeID, partnerID, periodID, pin)
            .finally(() => dispatch(loadingActions.unsetLoading(types.SEND_PAYMENTS)));
    };
}

export function getPaymentTypesByProgramPaymentType(programPaymentTypeID) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_PAYMENT_TYPES));
        return paymentTypeService
            .getMemberPaymentTypesByProgramPaymentType(programPaymentTypeID)
            .then((paymentTypes) => {
                dispatch(setPaymentTypesAction(paymentTypes));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_PAYMENT_TYPES));
            });
    };
}

const setPaymentTypesAction = (paymentTypes) => {
    return { type: types.SET_PAYMENT_TYPES, paymentTypes };
};

export default {
    getPartnersListByProgramPaymentType,
    setPeriodID,
    setProgramTypeID,
    getPayments,
    downloadPayments,
    checkMemberPaymentsProcessed,
    sendMemberPayments,
    getPaymentTypesByProgramPaymentType
};